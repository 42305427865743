import { queryKey, useDateTransformerFactory } from '@segunosoftware/equinox';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect } from 'react';
import { ACCOUNT, CACHED_ACCOUNT } from './query-keys';
import type { Get, Post } from './types';
import { useAuthenticatedFetch } from './useAuthenticatedFetch';

export type AccountBillingStatus = 'unbilled' | 'active' | 'frozen' | 'cancelled';
export type ReviewStatus = 'ignored' | 'reviewed' | 'denied' | 'deferred';

export type DismissedContent = {
	onboardingDismissed: boolean;
	suiteCardDismissed: boolean;
};

export type PostscriptPropertyNameMapping = {
	firstName?: string;
	lastName?: string;
	birthdate?: string;
};

export type Account = {
	id: number;
	shop: string;
	domain: string;
	timezone: string;
	currency: string;
	primaryLocale: string;
	platformPlan: string;
	ownerName: string;
	ownerEmail: string;
	userHash: string;
	supportUserHash: string;
	phone: string;
	name: string;
	billingStatus: AccountBillingStatus;
	reviewRating: number;
	reviewStatus: ReviewStatus;
	storefrontProtected: boolean;
	headless: boolean;
	dismissedContent: DismissedContent;
	paidFeaturesAvailable: boolean;
	paidPlan: boolean;
	freeWithSeguno: boolean;
	currentlyPaying: boolean;
	suspended: boolean;
	trialCompleted: boolean;
	trialDays: number;
	upgradedEarly: boolean;
	basePrice: number;
	cappedAmount: number;
	pricePerUnit: number;
	unitAmount: number;
	freeIncludedUnits: number;
	includedUnits: number;
	includedUnitsForPlan: number;
	chargeablePlan: boolean;
	brandingHideable: boolean;
	createdAt: Date;
	updatedAt: Date;
	trialEndsAt?: Date;
	shopCreatedAt?: Date;
	postscriptAccessToken?: string;
	postscriptConnected: boolean;
	postscriptDefaultKeywordId?: string;
	postscriptPropertyNameMapping: PostscriptPropertyNameMapping;
};

export function useAccountFetch(load = false) {
	const queryClient = useQueryClient();
	const { get } = useAuthenticatedFetch() as Get<Account>;

	const {
		data: account,
		refetch: fetchAccount,
		isFetching: isLoading
	} = useQuery({
		queryKey: queryKey(ACCOUNT),
		select: useDateTransformerFactory('trialEndsAt', 'shopCreatedAt'),
		queryFn: () => get('/auth/whoami'),
		enabled: load
	});
	useEffect(() => {
		const cachedAccount = queryClient.getQueryData<Account>(queryKey(CACHED_ACCOUNT));
		if (cachedAccount === null && !isLoading && account) {
			queryClient.invalidateQueries({ queryKey: queryKey(CACHED_ACCOUNT) });
		}
	}, [account, isLoading, queryClient]);
	return { account, fetchAccount, isLoading };
}

export function useAccount() {
	const { account } = useAccountFetch() as { account: Account | undefined };

	if (!account) {
		throw new Error('account not loaded'); // the account must be loaded before anyone uses this hook! (ProtectedRoute does this)
	}

	return account;
}

export function useCachedAccount() {
	const queryClient = useQueryClient();
	const { data: account } = useQuery({
		queryKey: queryKey(CACHED_ACCOUNT),
		queryFn: () => Promise.resolve(queryClient.getQueryData<Account>(queryKey(ACCOUNT)) || null)
	});
	return account;
}

export function useSetAccount() {
	const queryClient = useQueryClient();
	return useCallback(
		(account: Account) => {
			queryClient.setQueryData(queryKey(ACCOUNT), account);
			queryClient.invalidateQueries({ queryKey: queryKey(CACHED_ACCOUNT) });
		},
		[queryClient]
	);
}

export type SetReviewRatingPostData = {
	rating: number;
};

export type SetReviewStatusPostData = {
	reviewStatus: ReviewStatus;
};

export function useReviewRating() {
	const { post: setReviewRatingPost } = useAuthenticatedFetch() as Post<SetReviewRatingPostData, Account>;
	const { post: setReviewStatusPost } = useAuthenticatedFetch() as Post<SetReviewStatusPostData, Account>;
	const setAccount = useSetAccount();

	const { mutate: setReviewRating, isPending: isSetReviewRatingLoading } = useMutation({
		mutationFn: (reviewRating: number) => setReviewRatingPost('/account/review-rating', { rating: reviewRating }),
		onSuccess: setAccount
	});

	const { mutate: setReviewStatus, isPending: isSetReviewStatusLoading } = useMutation({
		mutationFn: (reviewStatus: ReviewStatus) => setReviewStatusPost('/account/review-status', { reviewStatus }),
		onSuccess: setAccount
	});

	return {
		setReviewRating: (reviewRating: number) => setReviewRating(reviewRating),
		isSetReviewRatingLoading,
		setReviewStatus: (reviewStatus: ReviewStatus) => setReviewStatus(reviewStatus),
		isSetReviewStatusLoading
	};
}
